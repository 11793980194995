@use '@angular/material' as mat;
@use 'sass:map';

/* You can add global styles to this file, and also import other style files */
@use '../node_modules/@angular/material-experimental' as matx;

//@import './material-theme';

@import "../node_modules/@aws-amplify/ui-angular/theme.css";

@include mat.core();

$_palettes: (
  primary: (
    0: #0E2E62,
    10: #183E77,
    20: #0056A0,
    25: #FF31B9,
    30: #FF31B9,
    35: #FF31B9,
    40: #0369B1, // Primary
    50: #1A72CB,
    60: #2C88E6,
    70: #FF31B9,
    80: #4DA1EA,
    90: #90C8F9,
    95: #FF31B9,
    98: #FF31B9,
    99: #D9F0FA,
    100: #F3F7FB,
  ),
  secondary: (
    0: #562C01,
    10: #924900,
    20: #BE660D,
    25: #FF31B9,
    30: #FF31B9,
    35: #FF31B9,
    40: #EE7F10, // Secondary
    50: #F2983F,
    60: #FAC48E,
    70: #FF31B9,
    80: #FF31B9,
    90: #FFE0C1,
    95: #FF31B9,
    98: #FF31B9,
    99: #FAF0E7,
    100: #FFFBF7,
  ),
  tertiary: (
    0: #024315,
    10: #075F20,
    20: #268240,
    25: #FF31B9,
    30: #FF31B9,
    35: #FF31B9,
    40: #199830, // Success
    50: #66B174,
    60: #ABEABD,
    70: #FF31B9,
    80: #FF31B9,
    90: #CEF0C8,
    95: #FF31B9,
    98: #FF31B9,
    99: #F0FBEF,
    100: #F9FFF8,
  ),
  neutral: (
    0: #222222,
    10: #253239,
    20: #384A53,
    25: #FF31B9,
    30: #FF31B9,
    35: #FF31B9,
    40: #566F7B,
    50: #7893A1,
    60: #C2D1DA,
    70: #FF31B9,
    80: #C2D1DA,
    90: #E1E9F0,
    95: #FF31B9,
    98: #FF31B9,
    99: #F3F7FA,
    100: #FFFFFF,
    4: #FF31B9,
    6: #FF31B9,
    12: #FF31B9,
    17: #FF31B9,
    22: #FF31B9,
    24: #FF31B9,
    87: #FF31B9,
    92: #FF31B9,
    94: #FF31B9,
    96: #FF31B9,

  ),
  neutral-variant: (
    0: #303030,
    10: #253137,
    20: #4A4A4A,
    25: #FF31B9,
    30: #1ed336,
    35: #FF31B9,
    40: #898A8C,
    50: #AAACAE,
    60: #B7B7B7,
    70: #FF31B9,
    80: #CACACA,
    90: #D8D8D8,
    95: #FF31B9,
    98: #FF31B9,
    99: #EAEAEA,
    100: #F7F7F7
  ),
  error: (
    0: #851C19,
    10: #A3221E,
    20: #C12C20,
    25: #FF31B9,
    30: #FF31B9,
    35: #FF31B9,
    40: #DA3224, // Error
    50: #E15347,
    60: #E9847C,
    70: #FF31B9,
    80: #FF31B9,
    90: #F2B5B0,
    95: #FF31B9,
    98: #FF31B9,
    99: #FBE5E4,
    100: #FEFAFA,
  ),
);

$_rest: (
    secondary: map.get($_palettes, secondary),
    neutral: map.get($_palettes, neutral),
    neutral-variant: map.get($_palettes, neutral-variant),
    error: map.get($_palettes, error),
);
$_primary: map.merge(map.get($_palettes, primary), $_rest);
$_tertiary: map.merge(map.get($_palettes, tertiary), $_rest);
$_error: map.merge(map.get($_palettes, error), $_rest);

$payx-theme: matx.define-theme((
    color: (
                theme-type: light,
                primary: $_primary,
                tertiary: $_tertiary,
            ),
            typography: (brand-family: 'Roboto'
            ),
            density: (scale: -3)));

.payx-setting {
    /* Global Color Variables */
    --primary: #{map-get(map-get($_palettes, primary), 40)};
    --secondary: #{map-get(map-get($_palettes, secondary), 40)};
    $navActive: #{map-get(map-get($_palettes, primary), 100)};
    $bgColor: #{map-get(map-get($_palettes, neutral), 99)};
    $labelDisabledColor: #898A8C;

    --mip-default-font-weight: 400;
    --mip-default-font-family: 'Roboto';

    --mip-spacing-xxsmall: 0.25rem;
    --mip-spacing-xsmall: 0.375rem;
    --mip-spacing-small: 0.5rem;
    --mip-spacing-med: 0.75rem;
    --mip-spacing-large: 1rem;
    --mip-spacing-xlarge: 1.25rem;

    --mip-font-weight-default: 400;
    --mip-font-weight-emphasized: 500;
    --mip-font-weight-bold: 600;

    --mip-font-size-small: 0.75rem;
    --mip-font-size-normal-small: 0.875rem;
    --mip-font-size-normal: 1rem;
    --mip-font-size-emphasized: 1.125rem;

    /* Where we'll put all the fun color theory stuff */
    /* Design Tokens */
    /* Custom Token Override for Disabled Chips */
    --mdc-chip-disabled-container-color: #7893A1;
    --mdc-chip-disabled-label-text-color: #F7F7F7;
    --mdc-chip-elevated-container-color: var(--primary);
    --mdc-chip-container-shape-radius: 4px;
    --mdc-chip-label-text-color: #{map-get(map-get($_palettes, neutral), 100)};
    --mdc-chip-with-trailing-icon-trailing-icon-color: #{map-get(map-get($_palettes, neutral), 100)};
    --required-asterisk-color: #DA3224;
    --mdc-filled-button-label-text-tracking: 0.56px;
    --mdc-filled-button-disabled-container-color: #{map-get(map-get($_palettes, neutral), 50)};
    --mdc-filled-button-disabled-label-text-color: #{map-get(map-get($_palettes, primary), 100)};
    --mdc-filled-button-label-text-weight: var(--mip-default-font-weight);
    --mdc-filled-button-container-height: 36px;
    --mdc-filled-button-container-color: var(--primary);
    --mdc-filled-button-container-shape: 3px;
    --mdc-text-button-label-text-tracking: 0.56px;
    --mdc-text-button-disabled-container-color: #{map-get(map-get($_palettes, neutral-variant), 80)};
    --mdc-text-button-disabled-label-text-color: #{map-get(map-get($_palettes, neutral), 100)};
    --mdc-text-button-container-height: 36px;
    --mdc-text-button-container-color: var(--primary);
    --mdc-text-button-container-shape: 3px;
    --mdc-text-button-label-text-color: #{map-get(map-get($_palettes, neutral-variant), 0)};
    --mat-card-title-text-line-height: 20px;
    --mat-card-title-text-size: 16px;
    --mdc-list-list-item-one-line-container-height: 40px;
    --mdc-list-list-item-container-color: #{map-get(map-get($_palettes, neutral), 100)};
    --mdc-list-list-item-label-text-size: 14px;
    --mdc-list-list-item-leading-icon-size: 20px;
    --mdc-list-list-item-leading-icon-color: #{map-get(map-get($_palettes, neutral-variant), 0)};
    --mdc-list-list-item-hover-leading-icon-color: #{map-get(map-get($_palettes, neutral-variant), 0)};;
    --mat-standard-button-toggle-shape: 4px;
    --mat-standard-button-toggle-selected-state-background-color: var(--primary);
    --mat-standard-button-toggle-selected-state-text-color: #{map-get(map-get($_palettes, neutral), 100)};
    --mat-option-selected-state-label-text-color: #{map-get(map-get($_palettes, neutral), 40)};
    --mat-option-focus-state-layer-color: #{map-get(map-get($_palettes, neutral), 99)};
    --mat-option-hover-state-layer-color: #F7F7F7;
    --mat-option-label-text-size: 14px;
    --mat-option-selected-state-layer-color: #E8F0F5;
    --mat-toolbar-container-background-color: var(--primary);
    --mat-toolbar-container-text-color: #{map-get(map-get($_palettes, neutral), 100)};
    --mat-toolbar-standard-height: 60px;
    --mat-toolbar-title-text-line-height: 20px;
    --mat-select-trigger-text-size: 14px;
    --mat-select-panel-background-color: #{map-get(map-get($_palettes, neutral), 100)};
    --mat-sidenav-container-divider-color: #B7B7B7;
    --mat-sidenav-container-shape: 0px;
    --mat-sidenav-container-width: 225px;
    --mat-sidenav-content-background-color: #{map-get(map-get($_palettes, neutral), 99)};
    --mat-expansion-container-background-color: #{map-get(map-get($_palettes, neutral-variant), 100)};
    --mat-expansion-header-description-color: #{map-get(map-get($_palettes, neutral-variant), 0)};
    --mat-expansion-header-indicator-color: #{map-get(map-get($_palettes, neutral-variant), 0)};
    --mat-table-background-color: #{map-get(map-get($_palettes, neutral), 100)};
    --mat-table-header-background-color: #{map-get(map-get($_palettes, neutral-variant), 100)};
    --mat-paginator-container-background-color: var(--mat-table-background-color);
    --mdc-elevated-card-container-color: #{map-get(map-get($_palettes, neutral), 100)};
    --mdc-elevated-card-border-color: #{map-get(map-get($_palettes, neutral-variant), 80)};
    --mdc-elevated-card-container-elevation: 0;
    --mdc-elevated-card-container-shape: 4px;
    --mdc-dialog-container-color: #{map-get(map-get($_palettes, neutral), 100)};
    --mdc-dialog-supporting-text-color: #{map-get(map-get($_palettes, neutral), 0)};
    --mdc-dialog-subhead-color: #{map-get(map-get($_palettes, neutral), 0)};
    --mdc-dialog-subhead-background-color: #{map-get(map-get($_palettes, neutral), 100)};
    --mdc-dialog-container-border-radius: 4px;
    --mdc-dialog-container-width: 445px;
    --mdc-calendar-header-day-background-color: #E0E0E0;
    --mdc-dialog-container-shape: 4px;
    --bs-font-sans-serif: 'Roboto';
    --mat-sidenav-container-background-color: #{map-get(map-get($_palettes, neutral), 100)};
    --mat-sidenav-container-text-color: #{map-get(map-get($_palettes, neutral), 0)};
    --mdc-icon-color: var(--primary);
    --mdc-icon-button-icon-color: #{map-get(map-get($_palettes, neutral-variant), 99)};
    --mat-icon-button-state-layer-color: #{map-get(map-get($_palettes, neutral-variant), 99)};
    --mat-icon-button-ripple-color: rgba(0,0,0,0);
    --mdc-checkbox-selected-icon-color: var(--primary);
    --mdc-plain-tooltip-supporting-text-color: #{map-get(map-get($_palettes, neutral), 100)};

    --mdc-circular-progress-active-indicator-color: var(--primary);

    --mip-menu-item-margin: 0.25rem;
    --mip-small-spinner-size: 1.25rem;
    --mat-list-option-checkbox-width: 2px;
    --mat-list-option-checkbox-height: 15px;
    --mat-responsive-selection-list-item-width: auto;
    --mat-responsive-selection-list-checkbox-start-margin-left: 0;
    --mat-autocomplete-background-color: #{map-get(map-get($_palettes, neutral), 100)};

    --mat-dialog-container-max-width: 100%;

    .nav-bar {
        --mip-mdc-list-list-item-is-active-background: #{$navActive};
        --mdc-list-list-item-hover-label-text-color: #{map-get(map-get($_palettes, neutral-variant), 0)};
        --mdc-list-list-item-focus-label-text-color: var(--primary);
        --mdc-list-list-item-active-label-text-color: var(--primary);
        --mdc-list-list-item-hover-state-layer-color: #{$navActive};
        --mdc-list-list-item-focus-state-layer-color: #{$navActive};
        --mdc-list-list-item-active-state-layer-color: #{$navActive};
        --mdc-list-list-item-label-text-weight: 400;
        --mdc-list-list-item-label-text-size: 14px;
        --mdc-list-list-item-label-text-line-height: 20px;
        --mdc-list-list-item-label-text-color: #{map-get(map-get($_palettes, neutral-variant), 0)};
    }

    .nav-item-is-active {
        background: var(--mip-mdc-list-list-item-is-active-background);
        --mdc-list-list-item-label-text-color: var(--primary);
        mat-icon{
            color: var(--primary);
        }
    }

    .nav-bar-spinner
    {
        display: var(--mip-nav-bar-nav-bar-spinner-display);
        justify-content: var(--mip-nav-bar-nav-bar-spinner-justify-content);
        align-items: var(--mip-nav-bar-nav-bar-spinner-align-items);
        vertical-align: var(--mip-nav-bar-nav-bar-spinner-display);
        margin-top:var(--mip-nav-bar-nav-bar-spinner-margin-top);
    }

    /* Styles for Avatar Profile Initials Circle */
    /* {component}-{element?}-{category}-{property} */
    --mip-profile-avatar-content-font-size: var(--mip-font-size-normal);
    --mip-profile-avatar-content-font-color: var(--primary);
    --mip-profile-avatar-div-width: 40px;
    --mip-profile-avatar-div-height: 40px;
    --mip-profile-avatar-div-border-radius: 50%;
    --mip-profile-avatar-div-color-background: #{map-get(map-get($_palettes, primary), 99)};
    --mip-profile-avatar-div-display: flex;
    --mip-profile-avatar-div-justify-content: center;
    --mip-profile-avatar-div-align-items: center;
    --mip-company-icon-content-font-size: var(--mip-font-size-normal);
    --mip-company-icon-content-font-color: #{map-get(map-get($_palettes, neutral), 100)};
    --mip-company-icon-div-color-background: #{map-get(map-get($_palettes, neutral-variant), 0)};
    --mip-company-icon-div-color-background-selected: #{map-get(map-get($_palettes, primary), 40)};
    --mip-company-icon-div-width: 20px;
    --mip-company-icon-div-height: 20px;
    --mip-company-icon-div-border-radius: 0;
    --mip-company-icon-div-display: flex;
    --mip-company-icon-div-justify-content: center;
    --mip-company-icon-div-align-items: center;

    --mdc-filled-text-field-focus-active-indicator-color: none;
    --mip-tenant-select-container-width: 20rem;
    --mip-tenant-select-container-min-width: 20rem;
    --mip-tenant-info-gutter-left: 20px; //shifts over the tenant-select to match where the navigation title is/value should be the differecne between the mat-toolbar-row and
    // styles for navigation bar
    --mip-nav-bar-nav-bar-item-padding: 10px 20px;
    --mip-nav-bar-nav-bar-item-gap: 10px;
    //styles for navigation bar nav bar spinner
    --mip-nav-bar-nav-bar-spinner-display: flex;
    --mip-nav-bar-nav-bar-spinner-justify-content: center;
    --mip-nav-bar-nav-bar-spinner-align-items: center;
    --mip-nav-bar-nav-bar-spinner-vertical-align: center;
    --mip-nav-bar-nav-bar-spinner-margin-top: 5px;
    --mip-nav-bar-nav-bar-spinner-margin-left: 8px;
    --mip-nav-bar-nav-icon-margin-right: 0px;
    //styles for last run date text
    --mip-last-run-text-neutrals-warm-grey-700-color: #707070;
    --mip-last-run-text-font-size: 0.75em;
    --mip-last-run-text-font-style: normal;
    --mip-last-run-text-font-weight: 400;
    --mip-last-run-text-margin-left: 8px;
    --mip-last-run-text-margin-top: 6px;
    //tabs
    --mip-tab-header-background: #{map-get(map-get($_palettes, neutral), 100)};
    --mip-tab-label-primary-text-color: var(--primary);
    --mat-tab-header-active-label-text-color: var(--primary);
    --mat-tab-header-active-focus-label-text-color: var(--primary);
    --mat-tab-header-active-hover-label-text-color: var(--primary);
    --mat-tab-header-label-text-weight: var(--mip-default-font-weight);
    --mat-tab-header-active-focus-indicator-color: var(--primary);
    --mat-tab-header-active-hover-indicator-color: var(--primary);
    --mdc-tab-indicator-active-indicator-color: var(--primary);
    //form
    --mdc-form-field-label-text-size: 14px;
    --mip-form-field-label-text-color-required: #C12C20;
    --mip-form-field-label-required-ind-text-color: #C12C20;
    --mip-form-field-label-required-ind--disabled-text-color: #{$labelDisabledColor};
    --mip-form-field-label-required-ind-text-weight: 600;
    --mdc-form-field-label-text-weight: 400;
    --mip-form-field-button-padding-top: 8px;
    --mip-form-field-button-padding-side: 16px;
    --mip-button-spacer-width: 10px;
    --mip-form-field-padding-top: 9px;
    --mip-form-field-gap: 6px;
    --mip-mat-mdc-form-field-bottom-align-before-height: 6px;
    --mdc-outlined-text-field-disabled-label-text-color: var(--mip-form-field-label-required-ind--disabled-text-color);
    --mdc-outlined-text-field-disabled-outline-color: #{map-get(map-get($_palettes, neutral-variant), 90)};
    --mdc-outlined-text-field-input-text-placeholder-color: #{map-get(map-get($_palettes, neutral-variant), 40)};
    --mat-form-field-leading-icon-color: #{map-get(map-get($_palettes, neutral), 0)};
    --mat-form-field-trailing-icon-color: #{map-get(map-get($_palettes, neutral), 0)};
    --mdc-outlined-text-field-label-text-color: #{map-get(map-get($_palettes, neutral), 0)};
    --mat-select-enabled-arrow-color: #{map-get(map-get($_palettes, neutral), 0)};
    --mdc-checkbox-unselected-icon-color: #{map-get(map-get($_palettes, neutral), 0)};
    --mdc-radio-unselected-icon-color: #{map-get(map-get($_palettes, neutral-variant), 90)};
    --mdc-radio-unselected-hover-icon-color: #{map-get(map-get($_palettes, primary), 90)};
    --mat-full-pseudo-checkbox-unselected-icon-color: #{map-get(map-get($_palettes, neutral), 0)};
    // Info panel
    --mip-info-box-accent-width: 5px;
    --mip-info-icon-size: 1.5rem;
    --mip-info-box-width-max: 36.25rem;
    --mip-info-box-width-min: 20rem;
    // Warning panel
    --mip-warning-icon-top-padding: .25rem;
    --mip-warning-icon-size: 1.5rem;
    // Links
    --mip-link-font-weight: 400;
    --mip-link-text-decoration: underline;
    --mip-link-font-color: var(--primary);

    //tenant drop down
    --mip-neutrals-warm-grey-200: #D8D8D8;
    --mip-neutrals-warm-grey-800: #4A4A4A;
    --mip-neutrals-warm-grey-900: #303030;
    --mip-primary-3-light-grey: #{map-get(map-get($_palettes, neutral-variant), 60)};
    --mip-primary-blue-000: #F3F7FB;

    --mdc-snackbar-supporting-text-color: #{map-get(map-get($_palettes, neutral), 100)};

    //plus icon button
    --mip-plus-icon-button-border: 0.063rem solid #{$labelDisabledColor};
    --mip-plus-icon-button-border-radius: 0.25rem;
    --mip-plus-icon-button-text-font-size: 0.875rem;
    --mip-plus-icon-button-text-font-weight: 400;
    --mip-plus-icon-button-text-line-height: 1.25rem;

    // label and dropdown button container
    --label-and-dropdown-button-container-width: 25rem;
    --mip-details-text-color: #707070;

    // bulk upload dialog box
    --mip-upload-status-message-text-color: #707070;

    .mat-mdc-form-field-bottom-align::before {
        height: var(--mip-mat-mdc-form-field-bottom-align-before-height);
    }

    .mdc-tab:hover {
        background-color: #{$navActive};
    }

    .mat-mdc-standard-chip.mdc-evolution-chip--disabled {
        background: var(--mdc-chip-disabled-container-color) !important;
        color: var(--mdc-chip-disabled-label-text-color) !important;
    }

    .mat-mdc-standard-chip.mdc-evolution-chip--disabled .mdc-chip__text {
        color: var(--mdc-chip-disabled-label-text-color) !important;
    }

    //integration
    --mat-tab-header-active-ripple-color: #{$navActive};
    --mat-list-list-item-leading-icon-end-space: 0px;
    --mip-integration-integration-title-text-color: var(--primary);
    --mip-integration-integration-title-padding-top: 20px;
    --mip-integration-integration-title-text-weight: var(--mip-default-font-weight);
    --mip-tab-header-hover-label-color: #{$navActive};
    // Navigation Bar
    --mip-navigation-bar-container-padding-left: 0px;
    --mip-navigation-bar-tab-body-gutter-left: 20px;
    --mip-navigation-bar-tab-body-gutter-top: 20px;
    --mip-navigation-bar-tab-body-gutter-bottom: 20px;
    --mat-tab-header-active-ripple-color: #{$navActive};
    --mat-list-list-item-leading-icon-end-space: 0px;
    // Sections
    // Section Divider (between subsections)
    --mip-section-container-section-divider-margin-top: 44px;
    --mip-section-container-section-divider-margin-bottom: 44px;
    // Subsections (ex: Tenant Settings Sections)
    --mip-subsection-text-color: #303030;
    --mip-subsection-title-padding-bottom: 8px;
    --mip-subsection-title-font-weight: 500;
    // Calendar
    --mat-datepicker-calendar-header-text-color:  #{map-get(map-get($_palettes, neutral-variant), 0)};
    --mat-datepicker-calendar-navigation-button-icon-color:  #{map-get(map-get($_palettes, neutral-variant), 0)};
    --mat-datepicker-calendar-period-button-text-color: #{map-get(map-get($_palettes, neutral-variant), 20)};
    --mat-datepicker-calendar-period-button-icon-color: #{map-get(map-get($_palettes, neutral-variant), 20)};

    // --mat-tab-header-inactive-ripple-color: #{$navActive};
    .nav-bar .nav-item {
        padding: var(--mip-nav-bar-nav-bar-item-padding);
        gap: var(--mip-nav-bar-nav-bar-item-gap);

        --mat-list-active-indicator-shape: 0;

        .mat-icon {
            margin-right: 0px;
        }

        .mdc-list-item__content {
            align-self: end;
            font-size: var(--mdc-list-list-item-label-text-size);
            line-height: var(--mdc-list-list-item-label-text-line-height);
            color: var(--mdc-list-list-item-label-text-color);
        }
    }

    //navigation-header
    .navigation-bar {
        background-color: var(--mip-tab-header-background);
        padding-left: var(--mip-navigation-bar-container-padding-left);
        padding-right: var(--mip-navigation-bar-container-padding-left);

        .navigation-title {
            padding-top: var(--mip-integration-integration-title-padding-bottom);
            padding-left: var(--mip-navigation-bar-tab-body-gutter-left);
        }

        .navigation-header {

            // Sections are the container for subsections and dividers. See Credentials (sections) and Credentials (section)
            .section-container-card {

                .section-container-header {
                    padding: 1rem 1.25rem;
                }

                .section-container-title {
                    font-size: 1rem;
                    line-height: 1.25rem;
                    font-weight: 500;
                    color: #{map-get(map-get($_palettes, neutral-variant), 0)};
                }

                .section-item {
                    border-bottom: 1px;
                }

                .section-divider {
                    margin-top: var(--mip-section-container-section-divider-margin-top);
                    margin-bottom: var(--mip-section-container-section-divider-margin-bottom);
                }

                // Subsection-specific things (i.e. individual credential item for example)
                .subsection {
                    .subsection-title {
                        margin-bottom: 0;
                        color: var(--mip-subsection-text-color);
                        font-weight: var(--mip-subsection-title-font-weight);
                        padding-bottom: var(--mip-subsection-title-padding-bottom);
                    }

                    .subsection-subtitle {
                        font-family: var(--mip-default-font-family);
                        font-size: var(--mdc-list-list-item-label-text-size);
                        font-weight: var(--mip-default-font-weight);
                        line-height: var(--mat-toolbar-title-text-line-height);
                        text-align: left;
                        margin-top: 8px;
                        margin-bottom: 22px;
                    }

                    .subsection-content-container {
                        border-bottom: 1px var(--mat-divider-color);
                    }

                    .subsection-action-buttons {
                        padding-top: 12px;
                        gap: 10px;
                    }
                }
            }
        }

        .mat-mdc-tab-body {
            background-color: #{$bgColor};
            padding: var(--mip-navigation-bar-tab-body-gutter-top);
        }

        .mat-mdc-tab-header {
            padding-left: var(--mip-navigation-bar-tab-body-gutter-top)
        }
    }

    h4 {
        color: var(--mip-integration-integration-title-text-color);
        font-weight: var(--mip-integration-integration-title-text-weight);
        font-size: 18px;
        font-family: "Roboto", sans-serif;
    }

    h5 {
        font-family: var(--mip-default-font-family);
        font-size: 16px;
        font-style: normal;
        font-weight: 600;
        line-height: 20px;
    }

    h6{
        text-align: center;
        font-family: var(--mip-default-font-family);
        font-size: 1rem;
        font-style: normal;
        font-weight: var(--mip-link-font-weight);
        line-height: 1.25rem;
        margin-bottom: 0;
    }

    .header-sso-login{
        text-align: center;
        display: flex;
        justify-content: center;
        margin-top:4.375rem;
        padding: 30px;
        padding-bottom: 0;
    }

    .container-payx-copyright-sso-login{
        text-align: center;
        display: flex;
        justify-content: center;
    }

    .payx-copyright-sso-login{
        font-weight: var(--mip-link-font-weight);
        font-size: small;
    }

    .privacy-and-terms-container-sso-login{
        text-align: center;
        display: flex;
        justify-content: center;
        margin-top: -20px;
        padding-bottom: 30px;
    }

    .privacy-and-terms-link-sso-login {
        font-size: small;
        padding: 7px 12px;
        cursor: pointer;
        font-weight: 400;
    }

    a:link {
        text-decoration: none;
    }

    a {
        color: var(--primary);
        font-weight: bold;
    }

    .link {
        font-weight: var(--mip-link-font-weight);
        text-decoration: var(--mip-link-text-decoration);
        color: var(--mip-link-font-color);
    }

    .amplify-button--link {
        font-weight: var(--mip-link-font-weight);
        color: var(--mip-link-font-color);
        text-decoration: underline;
    }

    a.link:link {
        font-weight: var(--mip-link-font-weight);
        text-decoration: var(--mip-link-text-decoration);
        color: var(--mip-link-font-color);
    }

    button[disabled] {
        opacity: 1;
    }

    .button-spacer {
        margin-right: var(--mip-button-spacer-width)
    }

    .button-primary {

        --mdc-text-button-label-text-tracking: 0.56px;
        --mdc-text-button-disabled-container-color: #{map-get(map-get($_palettes, neutral), 90)};
        --mdc-text-button-disabled-label-text-color: #{map-get(map-get($_palettes, neutral), 40)};
        --mdc-outlined-button-disabled-label-text-color: #{map-get(map-get($_palettes, neutral), 40)};
        --mdc-outlined-button-disabled-outline-color: #{map-get(map-get($_palettes, neutral), 40)};

        --mdc-text-button-container-height: 36px;
        --mdc-text-button-container-color: #{map-get(map-get($_palettes, neutral), 100)};
        --mdc-text-button-container-shape: 3px;
        --mdc-text-button-label-text-color: var(--primary);
        padding: var(--mip-form-field-button-padding-top) var(--mip-form-field-button-padding-side);
        gap: 10px;
        margin-right: 10px;

        &:disabled {
            background-color: #{map-get(map-get($_palettes, neutral), 90)};
        }
    }

    .button-secondary {
        --mdc-outlined-button-label-text-weight: var(--mip-default-font-weight);
        --mdc-outlined-button-label-text-tracking: 0.56px;
        --mdc-outlined-button-container-height: 36px;
        --mdc-outlined-button-container-color: #{map-get(map-get($_palettes, neutral), 100)};
        --mdc-outlined-button-container-shape: 3px;
        --mdc-text-button-label-text-tracking: 0.56px;
        --mdc-text-button-disabled-container-color: #{map-get(map-get($_palettes, neutral), 90)};
        --mdc-text-button-disabled-label-text-color: #{map-get(map-get($_palettes, neutral), 40)};
        --mdc-outlined-button-disabled-label-text-color: #{map-get(map-get($_palettes, neutral), 40)};
        --mdc-outlined-button-disabled-outline-color: #{map-get(map-get($_palettes, neutral), 40)};

        --mdc-text-button-container-height: 36px;
        --mdc-text-button-container-color: #{map-get(map-get($_palettes, neutral), 100)};
        --mdc-text-button-container-shape: 3px;
        --mdc-text-button-label-text-color: var(--primary);
        --mdc-outlined-button-label-text-color: var(--primary);
        --mdc-outlined-button-outline-color: #{map-get(map-get($_palettes, neutral-variant), 40)};
        --mat-outlined-button-hover-state-layer-opacity: 1;
        --mat-outlined-button-state-layer-color: #{map-get(map-get($_palettes, primary), 100)};
        padding: var(--mip-form-field-button-padding-top) var(--mip-form-field-button-padding-side);
        gap: 10px;
        margin-right: 10px;

        &:disabled {
            background-color: #{map-get(map-get($_palettes, neutral), 90)};
        }
    }

    .button-error {
        --mdc-filled-button-container-color: #{map-get($_error, 20)};
    }

    .button-validate-credentials {
        min-width: 5rem;
    }

    .button-sso-login {
        width: auto;
        padding: 10px 10px;
        margin: 0px 0px;
        background-color: #{map-get(map-get($_palettes, primary), 40)};
        color: #{map-get(map-get($_palettes, primary), 100)};;
        border: none;
        border-radius: 3px;
    }

    .container-button-sso-login {
        text-align: center;
        display: flex;
        justify-content: center;
        padding: 30px;
    }

    .plus-icon-button {
        border: var(--mip-plus-icon-button-border);
        border-radius: var(--mip-plus-icon-button-border-radius);
        --mdc-filled-button-container-color: #{map-get(map-get($_palettes, tertiary), 20)};

        .button-text{
            font-size: var(--mip-plus-icon-button-text-font-size);
            font-weight: var(--mip-plus-icon-button-text-font-weight);
            line-height: var(--mip-plus-icon-button-text-line-height);
        }
    }

    .add-button{
        margin-right: 0.5rem;
    }


    .enable-checkbox {
        margin-bottom: 10px;
    }

    .mat-mdc-card {
        border-color: var(--mdc-elevated-card-border-color);
        border-width: 1px;
        border-style: solid;
    }

    .mat-mdc-card-header {
        border-bottom-color: var(--mdc-elevated-card-border-color);
        border-bottom-width: 1px;
        border-bottom-style: solid;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 20px;
    }

    .mat-mdc-card-content:first-child {
        padding-top: 0px;
    }

    .mat-mdc-card-content.card-content-fill {
        padding: 0;
    }

    .mat-mdc-dialog-content {
        margin-top: 10px;
    }

    .mat-mdc-list-item:hover {
        background-color: var(--mdc-list-list-item-hover-state-layer-color);
        ;
    }

    .mat-mdc-dialog-title {
        background-color: var(--mdc-dialog-subhead-background-color);
    }

    .mat-mdc-list-option .mdc-checkbox {
        width: var(--mat-list-option-checkbox-width);
        height: var(--mat-list-option-checkbox-height);
    }

    .label-and-dropdown-button-container {
        display: flex;
        flex-direction: column;
        width: var(--label-and-dropdown-button-container-width);
        font-family: var(--mip-default-font-family);

        .view-table-label{
            font-size: 0.875rem;
            font-weight: var(--mip-default-font-weight);
            line-height: 1.25rem;
            margin-bottom: 0.375rem;
        }

        .view-table-label-disabled {
            color: #{map-get(map-get($_palettes, neutral-variant), 20)};
        }

    }

    .no-table-type-or-file-selected-container{
        font-family: var(--mip-default-font-family);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        margin-top: 0.5rem;
        padding-bottom: 2rem;

        .title{
            color: #{map-get(map-get($_palettes, neutral-variant), 0)};
            font-size: 1rem;
            font-weight: 500;
            line-height: 1.25rem;
            margin: 0.5rem 0 0 0;
        }

        .details{
            color: var(--mip-details-text-color);
            font-size: 0.875rem;
            font-weight: var(--mip-default-font-weight);
            line-height: 1.25rem;
            margin: 0;
        }
    }

    .button-row-container{
        margin-top: 0.5rem;
        display: flex;
        &.center-align{
            align-items: center;
            justify-content: center;
        }
    }

    .mat-mdc-select-placeholder {
        color: var(--mip-form-field-label-required-ind--disabled-text-color);
    }

    .table-settings-spinner {
        padding: 1rem 0;
    }

    .table-settings-container .mat-mdc-text-field-wrapper {
        padding: 0 0.563rem;
    }

    .table-settings-container .mat-mdc-select-placeholder {
        color: #{map-get(map-get($_palettes, neutral-variant), 0)};
        font-weight: var(--mip-default-font-weight);
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    .mat-mdc-paginator-container {
        padding: var(--mip-spacing-xlarge);
        border-top: solid 1px var(--mdc-elevated-card-border-color);
    }

    .align-right-down {
        align-items: start;
        flex-direction: column;
    }

    .mat-grid-tile-content {
        align-items: start;
    }

    .standard-gap-vertical {
        > * {
            margin-bottom: 10px;
        }
    }

    .icon-color-success {
        --mat-icon-color: #{map-get($_tertiary, 20)};
    }

    .icon-color-error {
        --mat-icon-color: #{map.get($_error, 20)};
    }

    .icon-color-primary {
        --mat-icon-color: #{map-get($_primary, 40)};
    }

    .table-icon-color-success {
        --mat-icon-color: #{map-get($_tertiary, 20)};
        cursor: pointer;
        margin-right: 1rem;
    }

    .table-icon-color-error {
        --mat-icon-color: #{map.get($_error, 20)};
        cursor: pointer;
        margin-right: 1rem;
    }

    .table-icon-color-primary {
        --mat-icon-color: var(--primary);
        cursor: pointer;
        margin-right: 1rem;
    }

    .hide-element{
        display: none !important;
    }

    .mip-table {
        width: 100%;
        border: 1px solid var(--mip-primary-3-light-grey);;
        overflow-x: auto;
    }

    .mip-table .mat-mdc-cell {
        min-width: 15.625rem;
        overflow-wrap: anywhere;
    }

    .mip-table-mat-card{
        border: none;
        max-height: 25rem;
        overflow: auto;
    }

    .mip-table .mat-mdc-header-row .mat-mdc-header-cell {
        background-color: var(--mat-table-header-background-color);
    }
    .mip-table .mat-mdc-header-cell {
        border: 1px solid var(--mip-primary-3-light-grey);
        min-width: 15.625rem;
    }

    .mip-table .mat-mdc-header-row{
        position: sticky;
        top: 0;
    }

    .mat-column-actions {
        max-width: 7.813rem;
    }

    .form-group {
        display: flex;
        flex-direction: column;
    }

    .field-readonly {
        display: flex;
        flex-wrap: wrap;
        align-items: baseline;
        padding-bottom: var(--mip-spacing-large);
    }

    .field-label-readonly {
        text-align: left;
        font-weight: 700;
        font-size: 1rem;
        min-width: 51px;
        padding-right: var(--mip-spacing-large);
    }

    .field-value-readonly {
        text-align: left;
        font-weight: var(--mip-default-font-weight);
        font-size: 1rem;
    }

    .field-required {
        color: var(--mip-form-field-label-required-ind-text-color);
        font-weight: var(--mip-form-field-label-required-ind-text-weight);
    }

    .field-disabled {
        color: var(--mip-form-field-label-required-ind--disabled-text-color);
    }

    .required-asterisk {
        color: var(--required-asterisk-color);
    }

    .mdc-floating-label--required:not(.mdc-floating-label--hide-required-marker)::after {
        --mdc-outlined-text-field-hover-label-text-color: #C12C20;
        color: var(--mdc-outlined-text-field-hover-label-text-color);
        content: "*";
        font-weight: var(--mip-form-field-label-required-ind-text-weight);
    }

    .md-toolbar-unauth {
        height: 200px;
        text-align: center;
        margin-bottom: -30px;
        font-size: 2.75em;
    }

    .mat-toolbar-row {
        justify-content: var(--amplify-components-accordion-item-trigger-justify-content);
        align-items: var(--amplify-components-accordion-item-trigger-align-items);
        padding-left: var(--mip-tenant-info-gutter-left);
    }

    .amplify-heading {
        padding: var(--amplify-space-medium) var(--amplify-space-small) var(--amplify-space-small) var(--amplify-space-medium);
        font-family: "Roboto", sans-serif;
        border-bottom: 1px solid #CACACA;
    }

    .totp {
        padding-left: 20px;
        padding-right: 20px;
        width: 460px;

        ol > li::marker {
            font-weight: bold;
        }

        .amplify-heading {
            padding: 15px 13px 15px 0px;
        }
    }

    .md-toolbar-unauth>mat-toolbar-row {
        display: block;
        margin: auto;
    }

    app-login > mat-grid-list mat-grid-tile {
        border-radius: 3px;
        overflow: unset;
    }

    .profile-avatar-wrapper {
        font-size: var(--mip-profile-avatar-content-font-size);
        color: var(--mip-profile-avatar-content-font-color);
        width: var(--mip-profile-avatar-div-width);
        height: var(--mip-profile-avatar-div-height);
        border-radius: var(--mip-profile-avatar-div-border-radius);
        background-color: var(--mip-profile-avatar-div-color-background);
        display: var(--mip-profile-avatar-div-display);
        justify-content: var(--mip-profile-avatar-div-justify-content);
        align-items: var(--mip-profile-avatar-div-align-items);
    }

    .company-icon {
        height: var(--mip-company-icon-div-height);
        width: var(--mip-company-icon-div-width);
        background-color: var(--mip-company-icon-div-color-background);
    }


    .company-avatar-wrapper {
        font-size: var(--mip-company-icon-content-font-size);
        color: var(--mip-company-icon-content-font-color);
        width: var(--mip-company-icon-div-width);
        height: var(--mip-company-icon-div-height);
        border-radius: 0;
        background-color: var(--mip-company-icon-div-color-background);
        display: var(--mip-company-icon-div-display);
        justify-content: var(--mip-company-icon-div-justify-content);
        align-items: var(--mip-company-icon-div-align-items);
    }

    .nav-item-is-active
    {
        .company-icon {
            background-color: var(--mip-company-icon-div-color-background-selected);
        }

        .company-avatar-wrapper {
            background-color: var(--mip-company-icon-div-color-background-selected);
        }
    }

    /* Angular Material Calendar / Datepicker Overrides */
    .mat-calendar-table-header {
        background-color: var(--mdc-calendar-header-day-background-color);
        width: 100%;

        th {
            padding: 8px 0 8px 0;
        }
    }

    .mat-calendar-table-header-divider {
        display: none;
        /* Previously useless padding of 8px */
    }

    .mat-calendar-body-selected {
        color: var(--mat-select-panel-background-color);
        background-color: var(--mdc-icon-color);
        border-color: var(--mdc-icon-color);
    }

    .mat-calendar-content {
        padding: 0px;
        /* Was 8, needs to be 0 to match paychex styles */
    }

    .mat-calendar-body-today:not(.mat-calendar-body-selected):not(.mat-calendar-body-comparison-identical) {
        border-color: var(--mdc-icon-color);
    }

    .mat-datepicker-content {
        border-radius: 3px;
        background-color: #{map-get(map-get($_palettes, neutral), 100)};
        box-shadow: 2px 2px 5px 1px rgba(0, 0, 0, 0.1);
    }

    .mat-datepicker-toggle {
        --mdc-icon-button-icon-color: #{map-get(map-get($_palettes, neutral-variant), 20)};
    }

    .suggested-input .mat-mdc-form-field-infix {
        background-color: #{map-get(map-get($_palettes, neutral), 100)};
        padding-left: 4px;
    }

    .suggested-input .mat-mdc-text-field-wrapper {
        background-color: #CACACA
    }

    .mat-mdc-form-field-icon-suffix,
    .mat-mdc-form-field-icon-prefix {
        padding: 0 4px 0 15px;
    }

    .mdc-line-ripple {
        display: none;
    }

    .mat-mdc-dialog-title {
        background-color: var(--mdc-dialog-subhead-background-color);
    }

    .mat-mdc-dialog-container {
        --mdc-dialog-container-shape: var(--mdc-dialog-container-border-radius);
    }

    .tenant-select {
        min-width: var(--mip-tenant-select-container-width);
        width: var(--mip-tenant-select-container-min-width);
        padding-top: 10px;
    }

    .app-confirmation-dialog {
        --mdc-dialog-subhead-size: 1.25em;
    }

    .loading-spinner {
        display: var(--mip-nav-bar-nav-bar-spinner-display);
        justify-content: var(--mip-nav-bar-nav-bar-spinner-justify-content);
        align-items: var(--mip-nav-bar-nav-bar-spinner-align-items);
        vertical-align: var(--mip-nav-bar-nav-bar-spinner-display);
        margin-top: var(--mip-nav-bar-nav-bar-spinner-margin-top);
        margin-left: var(--mip-nav-bar-nav-bar-spinner-margin-left)
    }

    .last-run-text {
        color: var(--mip-last-run-text-neutrals-warm-grey-700-color);
        font-family: var(--mip-default-font-family);
        font-size: var(--mip-last-run-text-font-size);
        font-style: var(--mip-last-run-text-font-style);
        font-weight: var(--mip-last-run-text-font-weight);
        margin-top: var(--mip-last-run-text-margin-top);
        margin-left: var(--mip-last-run-text-margin-left);
    }

    .mat-radio-group {
        display: flex;
        flex-direction: column;
        margin: 5px 0;
        align-items: flex-start;
    }

    //searchable drop down
    .app-searchable-dropdown {
        --mdc-outlined-text-field-outline-color: transparent;
    }

    .app-searchable-dropdown .mat-mdc-text-field-wrapper {
        border-bottom: none;
        border-bottom: 1px solid var(--mip-primary-3-light-grey);
    }

    .app-searchable-dropdown .mat-mdc-text-field-wrapper:focus-within {
        --mdc-outlined-text-field-: 0px;
        border-bottom: 1px solid var(--primary);
    }

    .searchable-dropdown-field {
        min-width: var(--mip-tenant-select-container-min-width);
    }

    .searchable-dropdown-field .mat-icon {
        color: var(--mip-last-run-text-neutrals-warm-grey-700-color);
    }

    .searchable-dropdown-field:focus-within {
        background: #{$navActive};
    }

    .searchable-input-field {
        text-overflow: ellipsis;
        --mdc-outlined-text-field-input-text-color: var(--mip-last-run-text-neutrals-warm-grey-700-color);
    }

    .searchable-input-field:focus {
        --mdc-outlined-text-field-input-text-color: var(--mip-neutrals-warm-grey-900);
    }

    .autocomplete-field .mat-mdc-option.mdc-list-item--selected:not(.mdc-list-item--disabled):not(.mat-mdc-option-multiple) {
        --mat-option-selected-state-layer-color: var(--mip-primary-blue-000);
        border: 1px solid var(--mip-neutrals-warm-grey-200);
        color: var(--mip-neutrals-warm-grey-900);
        --mdc-list-list-item-label-text-font: var(--mip-default-font-family);
        --mdc-list-list-item-label-text-size: 0.875rem;
        --mdc-list-list-item-label-text-weight: 500;
        --mat-option-label-text-weight: 500;
    }

    .autocomplete-field .mat-mdc-option.mdc-list-item:hover {
        --mat-option-selected-state-layer-color: var(--mip-primary-blue-000);
        border: 1px solid var(--mip-neutrals-warm-grey-200);
        color: var(--mip-neutrals-warm-grey-900);
    }

    //--mip-tenant-select-container-width: 20rem;
    //--mip-tenant-select-container-min-width: 20rem;
    .underlined-form-field .mat-mdc-text-field-wrapper {
        border-bottom: none;
        border-bottom: 1px solid var(--mip-primary-3-light-grey);
    }

    .mat-mdc-selection-list {
        --mip-mdc-list-list-item-is-active-background: #{$navActive};
        --mdc-list-list-item-hover-label-text-color: var(--primary);
        --mdc-list-list-item-focus-label-text-color: var(--primary);
        --mdc-list-list-item-active-label-text-color: var(--primary);
        --mdc-list-list-item-hover-state-layer-color: #{$navActive};
        --mdc-list-list-item-focus-state-layer-color: #{$navActive};
        --mdc-list-list-item-active-state-layer-color: #{$navActive};
        --mdc-list-list-item-container-color: #fffbff;
        --mdc-list-list-item-label-text-weight: 400;
        --mdc-list-list-item-label-text-size: 14px;
        --mdc-list-list-item-label-text-line-height: 20px;
    }

    .responsive-selection-list .mdc-list-item--with-leading-checkbox .mdc-list-item__start {
        margin-left: var(--mat-responsive-selection-list-checkbox-start-margin-left);
    }

    .info-card {
        display: flex;
        align-items: flex-start;
        justify-content: space-between;
        padding: var(--mip-spacing-med);
        border-left: var(--mip-info-box-accent-width) solid var(--primary);
        box-sizing: border-box;
        width: 100%; // Set the width to be dynamic for smaller screens
        max-width: var(--mip-info-box-width-max);
        min-width: var(--mip-info-box-width-min);
    }

    .info-content {
        display: flex;
        align-items: flex-start;
        flex: 1; /* Ensures it takes all available space except for the close button */
    }

    .info-icon {
        --mat-icon-color: var(--primary);
        margin-right: var(--mip-spacing-med);
        flex-shrink: 0; /* Prevents the icon from shrinking */
        font-size: var(--mip-info-icon-size); /* Adjust icon size */
    }

    .info-text {
        margin-top: 0px;
        margin-bottom: 0px;
    }

    .warning-icon {
        --mat-icon-color: var(--secondary);
        margin-left: var(--mip-spacing-med);
        margin-right: var(--mip-spacing-med);
        flex-shrink: 0; /* Prevents the icon from shrinking */
        font-size:  var(--mip-warning-icon-size); /*Adjust icon size */
        padding-top: var(--mip-warning-icon-top-padding);
    }

    .warning-text {
        padding-bottom: var(--mip-spacing-med);
    }

    .validation-label {
        display: flex;
        align-items: center;
        font-weight: var(--mip-default-font-weight);
        font-size: 0.875rem;
    }

    .success {
        color: #{map-get(map-get($_palettes, tertiary), 40)};
    }

    .error {
        color: #{map-get(map-get($_palettes, error), 40)};
    }

    .no-errors-container {
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: var(--mip-spacing-small);
        background-color: #{map-get(map-get($_palettes, neutral-variant), 100)};
        padding: var(--mip-spacing-xlarge);
    }

    .icon-large {
        font-size: 1.5rem;
    }

    .divider-validate-credentials{
        margin: 10px 0px;
    }

    .bulk-upload {
        --mat-dialog-container-max-width: 37.5rem;
        --mdc-dialog-container-width: 37.5rem;
    }

    .dialog-container-common {
        width: var(--mdc-dialog-container-width);
        background-color: #{map-get(map-get($_palettes, neutral), 100)};
        font-family: var(--mip-default-font-family);
        border: #{map-get(map-get($_palettes, neutral-variant), 100)} 0.063rem solid;
        border-radius: var(--mdc-dialog-container-border-radius);
        border-top: var(--primary) 0.313rem solid;

        mat-dialog-content {
            padding: 0;
        }

        mat-card-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: 0;
            border-bottom-color: #{map-get(map-get($_palettes, neutral-variant), 90)};

            .disable-dialog-close-icon-button-container{
                margin-right: 1.25rem;
            }

            .disable-dialog-close-icon{
                color: #{map-get(map-get($_palettes, neutral-variant), 20)};
                font-size: 1.25rem;
            }
        }
    }

    .dialog-title-common {
        font-family: var(--mip-default-font-family);
        color: #{map-get(map-get($_palettes, neutral-variant), 0)};
        font-size: 1.125rem;
        line-height: 1.25rem;
        margin: 1.25rem 1.25rem 1rem 1.25rem;
        font-weight: 500;
    }

    .dialog-content-common {
        font-family: var(--mip-default-font-family);
        display: flex;
        flex-direction: column;
        align-items: center;
        margin: 1.25rem;
    }

    .dialog-content-common.has-scrollbar {
        margin-right: 0;
    }

    .dialog-container.job-item-error-dialog {
        width: unset;
    }

    .dialog-title-container.dialog-title-container-job-item-error {
        padding: 1.25rem 1.25rem 0.75rem 1.25rem;
        border-bottom: solid 1px #{map-get(map-get($_palettes, neutral-variant), 80)};
    }

    .table-container-scrollable {
        overflow-y: auto;
    }

    .dialog-table-container-scrollable {
        max-height: 300px;
    }

    .select-file-button {
        margin: 0.5rem 1rem 0.5rem 0.5rem;
        padding: 0.5rem 1rem;
        font-size: 0.875rem;
        font-weight: var(--mip-default-font-weight);
        line-height: 1.25rem;
        flex-shrink: 0;
    }

    .file-upload-container {
        display:flex;
        width:100%;
    }

    .file-select-container {
        display: flex;
        flex-direction: row;
        align-items: left;

        border: 1px solid #{map-get(map-get($_palettes, neutral-variant), 90)};
        border-radius: 0.375rem;
        width: 100%;
        margin-right: 1.25rem;

        .file-input {
            display: none;
        }

        .file-name-text {
            font-size: 0.875rem;
            font-weight: var(--mip-default-font-weight);
            color: #{map-get(map-get($_palettes, neutral-variant), 0)};
            line-height: 1.25rem;
            letter-spacing: 0.016rem;
            padding-top: 1rem;
        }
    }

    .upload-icon {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.5rem 0;
    }

    .no-file-selected {
        text-align: center;
        font-size: 0.875rem;
        font-weight: 700;
        color: #{map-get(map-get($_palettes, neutral-variant), 0)};
        line-height: 1.25rem;
        margin: 0;
    }

    .upload-status-message {
        text-align: center;
        color: var(--mip-upload-status-message-text-color);
        font-size: 0.875rem;
        font-weight: var(--mip-default-font-weight);
        line-height: 1.25rem;
        margin: 0;
        padding-bottom: 1.5rem;
    }

    .status-icon-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin: 0.5rem 0;

        .icon-display {
            width: 5.625rem;
            height: 5.625rem;
        }
    }

    .status-text-container {
        color: #{map-get(map-get($_palettes, neutral), 0)};
        font-size: 0.875rem;
        text-align: center;

        .title{
            font-weight: 700;
        }
        .value{
            font-weight: var(--mip-default-font-weight);
        }
    }

    .job-id-text-container {
        color: #{map-get(map-get($_palettes, neutral), 0)};
        font-size: 0.875rem;
        text-align: center;
        padding-bottom: 1.5rem;

        .title{
            font-weight: 700;
        }
        .value{
            font-weight: var(--mip-default-font-weight);
        }
    }

    .footer-divider {
        color: #{map-get(map-get($_palettes, neutral-variant), 90)};
        margin: 0;
        border-top-width: 0.063rem;
        border-top-style: solid;
        opacity: 100%;
    }

    .dialog-actions-common {
        padding: 0;
        min-height: 0;

        .close-button{
            width: 5rem;
            color: var(--primary);
            margin: 0.625rem 1.25rem;
            border: 0.063rem solid #{map-get(map-get($_palettes, neutral-variant), 40)};
            font-size: 0.875rem;
            font-weight: var(--mip-default-font-weight);

            &:disabled {
                color: #{map-get(map-get($_palettes, neutral), 40)};
                background-color: #{map-get(map-get($_palettes, neutral), 90)};
            }
        }

        .cancel-button{
            margin-right: 0.125rem;
        }

        .save-button{
            margin: 0.625rem 1.25rem;
        }
    }

    .dialog-title-warning {
        font-family: var(--mip-default-font-family);
        font-weight: 500;
        color: #{map-get(map-get($_palettes, neutral-variant), 0)};
        font-size: 1.125rem;
    }

    .dialog-content-warning {
        margin: 1.25rem;
    }

    .dialog-content-warning-text {
        font-family: var(--mip-default-font-family);
        font-weight: var(--mip-default-font-weight);
        color: #{map-get(map-get($_palettes, neutral-variant), 0)};
        font-size: 1rem;
        line-height: 1.172rem;
    }

    .dialog-container-warning {
        background-color: #{map-get(map-get($_palettes, neutral), 100)};
        font-family: var(--mip-default-font-family);
        border: #{map-get(map-get($_palettes, neutral-variant), 100)} 0.063rem solid;
        border-radius: var(--mdc-dialog-container-border-radius);
        border-top: var(--secondary) 0.313rem solid;

        mat-dialog-content {
            padding: 0;
        }

        mat-card-header {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: space-between;
            padding: .5rem;
            border-bottom-color: #{map-get(map-get($_palettes, neutral-variant), 90)};

            .disable-dialog-close-icon-button-container{
                margin-right: 1.25rem;
            }

            .disable-dialog-close-icon{
                color: #{map-get(map-get($_palettes, neutral-variant), 20)};
                font-size: 1.25rem;
            }
        }
    }

    .dialog-container {
        width: 425px;
        border-radius: 4px;
    }

    .dialog-container-info {
        width: auto;
        border-radius: 4px;
    }

    .dialog-title-container {
        border-top: 5px solid;
        display: flex;
        align-items: center;
        justify-content: space-between;
        gap: 10px;
        padding: 1.25rem 1.25rem 0.75rem 1.25rem;
        --mat-dialog-headline-padding: 0px 24px 13px
    }

    .dialog-title-container-info {
        border-color: #{map-get(map-get($_palettes, primary), 40)};
        padding: 0.0rem 1.25rem 0.0rem 0.0rem;
        --mat-dialog-with-actions-content-padding: 10px 10px 0px 10px;
    }

    .dialog-title-container-busy {
        border-color: #{map-get(map-get($_palettes, primary), 40)};
    }

    .dialog-title-container-success {
        border-color: #{map-get(map-get($_palettes, tertiary), 40)};
        padding: 0.0rem 1.25rem 0.0rem 0.0rem;
        --mat-dialog-with-actions-content-padding: 10px 10px 0px 10px;
    }

    .dialog-title-container-error {
        border-color: #{map-get(map-get($_palettes, error), 40)};
        padding: 0.0rem 1.25rem 0.0rem 0.0rem;
        --mat-dialog-with-actions-content-padding: 10px 10px 0px 10px;
    }

    .dialog-title-text {
        --mdc-dialog-subhead-font-: var(--mip-default-font-family);
        --mdc-dialog-subhead-weight: 600;
        --mdc-dialog-subhead-size: 1rem;
        --mdc-dialog-subhead-line-height: 1.25rem;
        height:1rem;
        flex-grow: 1;
        margin: 0;
        padding: 0;
        align-items: center;
        display: flex;
        background-color: inherit;
        --mdc-dialog-subhead-color: #{map-get(map-get($_palettes, neutral-variant), 0)};
    }

    .dialog-content-container {
        --mat-dialog-content-padding: 0.75rem 1.25rem 1.25rem 1.25rem;//top right bottom left
        margin: 0;
    }

    .dialog-content-container-info {
        justify-content: center;
        display: flex;
    }

    .dialog-content-container-busy {
        justify-content: center;
        display: flex;
    }

    .dialog-content-text {
        white-space: pre-line;
    }

    .button-close {
        --mat-icon-color: #{map-get(map-get($_palettes, neutral-variant), 10)};
        font-size: 20px;
    }

    .snackbar-container {
        display: flex;
        align-items: center;
        padding: 0;
        mat-icon{
            font-size: 1rem;
            line-height: 1rem;
            width: 1rem;
            height: 1rem;
        }
        .bold-check-icon{
            font-weight: 900;
        }
    }

    .snackbar-message {
        margin-left: 0.625rem;
    }

    .snackbar-close-button{
        font-size: 0.875rem;
        line-height: 1.25rem;
        font-weight: 500;
        color: #{map-get(map-get($_palettes, neutral), 100)};
        margin-left: 1.875rem;
        cursor: pointer;
    }
    .mat-mdc-snack-bar-container .mdc-snackbar__surface{
        min-width: 0;
    }

    .mat-mdc-snack-bar-container .mdc-snackbar__label{
        padding: 0.75rem 1.25rem;
    }

    .snackbar-success {
        --mdc-snackbar-supporting-text-color: #{map-get(map-get($_palettes, tertiary), 99)};
        --mat-snack-bar-button-color: #{map-get(map-get($_palettes, neutral), 100)};
        --mdc-snackbar-container-color: #{map-get(map-get($_palettes, tertiary), 40)};
        --mdc-snackbar-supporting-text-weight: 500;
    }

    .snackbar-error {
        --mdc-snackbar-supporting-text-color: #{map-get(map-get($_palettes, tertiary), 99)};
        --mat-snack-bar-button-color: #{map-get(map-get($_palettes, neutral), 100)};
        --mdc-snackbar-container-color: #{map-get(map-get($_palettes, error), 40)};
        --mdc-snackbar-supporting-text-weight: 500;
    }

    .tab-content-header{
        margin-bottom: var(--mip-spacing-xlarge);
    }

    .tab-content-header-title {
        margin-bottom: var(--mip-spacing-small);
    }

    .form-container .mat-mdc-text-field-wrapper {
        padding: 0 0.563rem;
    }

    .form-container .mat-mdc-select-placeholder {
        color: #{map-get(map-get($_palettes, neutral-variant), 40)};
        font-weight: var(--mip-default-font-weight);
        font-size: 0.875rem;
        line-height: 1.25rem;
    }

    .input-container{
        display: flex;
        flex-direction: column;
        margin-bottom: 1rem;
        width: 100%;

        mat-label {
            color: #{map-get(map-get($_palettes, neutral-variant), 0)};
            margin-right: 0.25rem;
            margin-bottom: 0.375rem;
        }

        .mat-mdc-form-field-subscript-wrapper {
            height: 0;
        }

        .input-validation-error-message{
            margin-top: 0.375rem;
            font-size: 0.75rem;
            line-height: 0.75rem;
            color: #{map-get(map-get($_palettes, error), 20)};
        }
    }

    .input-container.scroll-padding{
        padding-right: 1.25rem;
    }

    .label-and-asterisk-container{
        display: flex;
        flex-direction: row;
    }

    .configuration-out-of-sync {
        margin-bottom: 20px;
        max-width: 100%;
        width: fit-content;
        box-shadow: 0px 4px 4px 0px #00000040;
    }

    .offering-name {
        font-weight: var(--mip-font-weight-bold);
        font-size: var(--mip-font-size-emphasized);
        line-height: var(--mip-company-icon-div-height);
    }

    .offering-title-container {
        margin-bottom: var(--mip-spacing-small);
    }

    .offering-integration-select {
        width: auto;
        min-width: var(--mip-tenant-select-container-min-width);

        --mat-select-trigger-text-weight: var(--mip-font-weight-normal);
        --mat-select-trigger-text-siz: var(--mip-font-size-normal);

        .mat-mdc-select-arrow {
            padding-left: var(--mip-spacing-large);
            display: none;
        }

        .mat-mdc-select-trigger {
            border-bottom: 1px solid #{map-get(map-get($_palettes, neutral), 90)};
            position: relative;
            padding-right: calc(var(--mip-font-size-small) + var(--mip-spacing-xxsmall));
        }
        .mat-mdc-select-trigger::after {
            content: 'expand_more';
            font-family: 'Material Icons';
            font-size: var(--mip-font-size-small);
            font-weight: 900;
            color: #{map-get(map-get($_palettes, neutral-variant), 40)};
            position: absolute;
            right: 0;
            top: 50%;
            transform: translateY(-50%);
            pointer-events: none;
        }
    }

    .offering-component{
        .integration-title{
            // Hide the integration title when the integration is nested in the offering.
            display:none;
        }
    }
}

body.payx-setting {
    background-color: #{map-get(map-get($_palettes, neutral), 99)};
}

//This style shouldnt be needed but for whatever reason the calc on bs-gutter-x is not working properly
.mdc-card__actions {
    padding-top: 0px !important;
    padding-bottom: 0px !important;
    padding-right: 16px !important;
    padding-left: 16px !important;
}

.full-width {
    width: 100%;
}

.flex-start {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.flex-centered-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;
}

.flex-baseline-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: baseline;
}

.flex-start-row {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: flex-start;
}

.flex-xsmall-spacing {
    gap: var(--mip-spacing-xsmall);
}

.flex-small-spacing {
    gap: var(--mip-spacing-small);
}

.flex-med-spacing {
    gap: var(--mip-spacing-med);
}

.flex-large-spacing {
    gap: var(--mip-spacing-large);
}

.flex-xlarge-spacing {
    gap: var(--mip-spacing-xlarge);
}

.mdc-card__actions-spinner {
    margin-left: 10px;
    align-self: center;
    justify-self: center;
    margin-right: 10px;
}

html {
    @include mat.all-component-themes($payx-theme);
}

html,
body {
    height: 100%;
}

strong {
    font-weight: 600;
}

.main {
    min-height: calc(100lvh - 90px);
}

.center {
    display: flex;
    align-content: center;
    align-items: center;
    justify-content: center;
}

.full-width {
    width: 100%
}

.margin-bottom-10 {
    margin-bottom: .625rem;
}

.alert-danger {
    background-color: #f44336;
    /* Red */
}

.alert-warn {
    background-color: #ff6a00;
}

.alert-notify {
    background-color: lightgray;
    color: black !important;
}

.alert {
    color: white;
    margin-bottom: 15px;
    text-align: center;
    border-radius: 3px;
}

.job-container-spaceing {
    margin-left: 1.5rem;
}

.margin-left-10 {
    margin-left: 10px;
}

.illustration-container {
    display: flex;
    justify-content: center;
    align-items: center;
    vertical-align: center;
}

/* Illustration Placeholder */
.illustration-placeholder {

    position: relative;
    width: 100px;
    height: 100px;

    /* Rectangle 40 */
    .rectangle {
        position: absolute;
        left: 0%;
        right: 0%;
        top: 0%;
        bottom: 0%;

        background: #D9D9D9;
    }


    /* Placeholder Illustration */
    .illustration {

        position: absolute;
        left: 0%;
        right: 0%;
        top: 36.5%;
        bottom: 27.5%;

        font-family: 'Roboto';
        font-style: normal;
        font-weight: 400;
        font-size: 12px;
        line-height: 20px;
        /* or 167% */
        text-align: center;


        /* Neutrals/warmGrey-800 */
        color: #4A4A4A;
    }
}

.chip-grid-inline {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    margin-bottom: 0;
}

.mat-chip-row {
    margin: 4px;
}

.mat-form-field {
    margin-bottom: 0;
}


.text-area-medium {
    max-width: 600px;
}

.select-box-small {
    max-width: 200px;
}

[data-bs-theme] {
    --bs-font-sans-serif: 'Roboto', sans-serif;
}

[data-amplify-authenticator] {
    --amplify-colors-primary-100: var(--mdc-filled-button-container-color);
    --amplify-colors-secondary-100: var(--mdc-filled-button-container-color);
    --amplify-colors-secondary-90: #{map-get(map-get($_palettes, primary), 60)};
    --amplify-colors-secondary-80: #{map-get(map-get($_palettes, primary), 50)};
    --amplify-components-fieldcontrol-border-radius: var(--mdc-filled-button-container-shape);
    --amplify-components-button-primary-background-color: var(--amplify-colors-primary-100);
    --amplify-components-button-primary-hover-background-color: var(--amplify-colors-secondary-90);
    --amplify-components-button-primary-focus-background-color: var(--amplify-colors-secondary-90);
    --amplify-components-button-primary-active-background-color: var(--amplify-colors-secondary-100);
    --amplify-components-button-link-color: var(--mip-link-font-color);
    --amplify-components-button-link-hover-color: var(--amplify-colors-secondary-90);
    --amplify-components-button-link-focus-color: var(--amplify-colors-secondary-90);
    --amplify-components-button-link-active-color: var(--amplify-colors-secondary-100);
    --amplify-components-button-link-active-background-color: transparent;
    --amplify-components-button-link-focus-background-color: transparent;
    --amplify-components-button-link-hover-background-color: transparent;
    --amplify-fonts-default-static: 'Font Awesome 6 Free', 'Roboto';
    --amplify-fonts-default-variable: 'Font Awesome 6 Free', 'Roboto';
    --amplify-components-authenticator-container-width-max: 400px;
    --bs-body-font-family: 'Font Awesome 6 Free', 'Roboto', sans-serif;
}

[data-amplify-authenticator] [data-amplify-container] {
    --amplify-components-authenticator-max-width: auto;
    --amplify-components-authenticator-container-width-max: auto;

    img {
        align-self: center;
        justify-self: center;
    }
}

[data-amplify-authenticator] [data-amplify-router] {
    background-color: var(--amplify-components-authenticator-router-background-color);
    box-shadow: var(--amplify-components-authenticator-router-box-shadow);
    border-color: var(--amplify-components-authenticator-router-border-color);
    border-width: var(--amplify-components-authenticator-router-border-width);
    border-style: none;
    border-radius: 3px;
}

footer {
    text-align: right;
    margin-right: 25px;
}

.small-toggle.mat-mdc-slide-toggle {
    // Selected
    --mip-selected-text-color: #268240;
    --mip-selected-track: #268240;
    --mip-selected-border-color: #268240;
    --mip-selected-border-size: 0px;
    --mip-selected-handle-color: #{map-get(map-get($_palettes, neutral), 100)};

    // Selected-hover
    --mip-selected-hover-track: #268240;
    --mip-selected-hover-border-color: #206634;
    --mip-selected-hover-border-size: 2px;
    --mip-selected-hover-handle-color: #{map-get(map-get($_palettes, neutral), 100)};

    // Selected-disabled
    --mip-disabled-text-color: #898A8C;
    --mip-selected-disabled-track: #AAACAE;
    --mip-selected-disabled-border-color: #AAACAE;
    --mip-selected-disabled-border-size: 0px;
    --mip-selected-disabled-handle-color: #FEF7FF;

    // Selected-disabled-hovered
    --mip-selected-disabled-hover-track: #EAEAEA;
    --mip-selected-disabled-hover-border-color: #1D1B20;
    --mip-selected-disabled-hover-border-size: 2px;
    --mip-selected-disabled-hover-handle-color: #1D1B20;

    // Unselected
    --mip-unselected-text-color: #EE7F10;
    --mip-unselected-track: #EE7F10;
    --mip-unselected-border-color: #EE7F10;
    --mip-unselected-border-size: 0px;
    --mip-unselected-handle-color: #{map-get(map-get($_palettes, neutral), 100)};

    // Unselected-hover
    --mip-unselected-hover-track: #EE7F10;
    --mip-unselected-hover-border-color: #C3690F;
    --mip-unselected-hover-border-size: 2px;
    --mip-unselected-hover-handle-color: #{map-get(map-get($_palettes, neutral), 100)};

    // Unselected-disabled
    --mip-unselected-disabled-track: #EAEAEA;
    --mip-unselected-disabled-border-color: #EAEAEA;
    --mip-unselected-disabled-border-size: 0px;
    --mip-unselected-disabled-handle-color: #1D1B20;

    // Unselected-disabled-hovered
    --mip-unselected-disabled-hover-track: #EAEAEA;
    --mip-unselected-disabled-hover-border-color: #707070;
    --mip-unselected-disabled-hover-border-size: 2px;
    --mip-unselected-disabled-hover-handle-color: #707070;

    --mip-track-border-size: 2px;


    // Selected
    --mdc-switch-selected-track-color: var(--mip-selected-track);
    --mdc-switch-unselected-icon-color: var(--mip-selected-handle-color);
    --mdc-switch-selected-handle-color: var(--mip-selected-handle-color);
    --mdc-switch-selected-icon-size: 0px;

    // Selected - hover
    --mdc-switch-selected-focus-track-color: var(--mip-selected-hover-track);
    --mdc-switch-selected-hover-track-color: var(--mip-selected-hover-track);
    --mdc-switch-selected-pressed-track-color: var(--mip-selected-hover-track);
    --mdc-switch-selected-focus-handle-color: var(--mip-selected-hover-handle-color);
    --mdc-switch-selected-hover-handle-color: var(--mip-selected-hover-handle-color);
    --mdc-switch-selected-pressed-handle-color: var(--mip-selected-hover-handle-color);

    // Selected - disabled
    --mat-switch-disabled-selected-track-outline-color: var(--mip-selected-disabled-border-color);
    --mdc-switch-disabled-selected-track-color: var(--mip-selected-disabled-track);
    --mdc-switch-disabled-selected-track-outline-width: var(--mip-selected-hover-border-size);
    --mdc-switch-disabled-track-opacity: 1;

    // Unselected
    --mdc-switch-unselected-track-color: var(--mip-unselected-track);
    --mdc-switch-unselected-icon-color: var(--mip-unselected-handle-color);
    --mdc-switch-unselected-handle-color: var(--mip-unselected-handle-color);
    --mat-switch-track-outline-color: var(--mip-unselected-border-color);
    --mdc-switch-unselected-icon-size: 0px;

    // Unselected - hover
    --mdc-switch-unselected-focus-track-color: var(--mip-unselected-hover-track);
    --mdc-switch-unselected-hover-track-color: var(--mip-unselected-hover-track);
    --mdc-switch-unselected-pressed-track-color: var(--mip-unselected-hover-track);
    --mdc-switch-unselected-focus-handle-color: var(--mip-unselected-hover-handle-color);
    --mdc-switch-unselected-hover-handle-color: var(--mip-unselected-hover-handle-color);
    --mdc-switch-unselected-pressed-handle-color: var(--mip-unselected-hover-handle-color);

    // Unselected - disabled
    --mat-switch-disabled-unselected-track-outline-color: var(--mip-unselected-disabled-border-color);
    --mdc-switch-disabled-unselected-track-color: var(--mip-unselected-disabled-track);
    --mdc-switch-disabled-unselected-track-outline-width: var(--mip-unselected-hover-border-size);
    --mdc-switch-disabled-unselected-handle-color: var(--mip-unselected-disabled-handle-color);

    --mdc-form-field-label-text-color: var(--mip-unselected-text-color);
    --mdc-switch-disabled-label-text-color: var(--mip-disabled-text-color);
    --mat-switch-unselected-track-outline-width: var(--mip-track-border-size);

    // sizing


    --mdc-switch-pressed-handle-size: 0px;
    --mat-switch-with-icon-handle-size: 16px;
    --mdc-switch-state-layer-size: 24px;

    --mat-switch-selected-with-icon-handle-horizontal-margin: 0px;
    --mat-switch-unselected-with-icon-handle-horizontal-margin: 0px var(--mip-unselected-hover-border-size);
    --mat-switch-selected-pressed-handle-horizontal-margin: 0px;
    --mdc-switch-handle-height: 16px;
    --mat-switch-pressed-handle-size: 16px;
    --mdc-switch-handle-width: 18px;
    --mdc-switch-track-height: 20px;
    --mdc-switch-track-width: 32px;
    --mdc-switch-track-outline-width: var(--mip-track-border-size);
    --mat-switch-track-outline-width: var(--mip-track-border-size);

    --mdc-form-field-label-text-line-height: 0.875rem;
    --mdc-form-field-label-text-size: 0.75rem;
    --mdc-form-field-label-text-weight: 500;

    &.mat-mdc-slide-toggle-checked {
        // Checked state
        --mdc-switch-label-text-color: var(--mip-selected-text-color);
        --mdc-form-field-label-text-color: var(--mip-selected-text-color);
        --mat-switch-track-outline-color: var(--mip-selected-border-color);
        // Looks like a bug in materials. Set the unselected track outline color
        --mat-switch-disabled-unselected-track-outline-color: var(--mip-selected-disabled-border-color);

        &:hover {
            --mat-switch-track-outline-color: var(--mip-selected-hover-border-color);
            --mat-switch-selected-track-outline-width: var(--mip-selected-hover-border-size);
        }

        &:disabled {
            --mat-switch-track-outline-color: var(--mip-selected-disabled-border-color);
        }

    }

    &:hover {
        --mat-switch-track-outline-color: var(--mip-unselected-hover-border-color);
    }

    &.mdc-switch--disabled {
        --mdc-switch-label-text-color: var(--mip-disabled-text-color);
        --mdc-form-field-label-text-color: var(--mip-disabled-text-color);
    }

    &.mat-mdc-slide-toggle-disabled {
        --mdc-switch-label-text-color: var(--mip-disabled-text-color);
        --mdc-form-field-label-text-color: var(--mip-disabled-text-color);
    }

    &:disabled {
        --mdc-switch-label-text-color: var(--mip-disabled-text-color);
        --mdc-form-field-label-text-color: var(--mip-disabled-text-color);
    }
}

.inline-spinner.mat-mdc-progress-spinner {
    width: var(--mip-small-spinner-size) !important;
    height: var(--mip-small-spinner-size) !important;
    --mdc-circular-progress-size: var(--mip-small-spinner-size) !important;
    --mdc-circular-progress-active-indicator-width: var(--mip-small-spinner-size) !important;
    margin: var(--mip-menu-item-margin);
}
